/* eslint-disable eqeqeq */
export function getPackageStatusText(status: number | string) {
  if (status == 0) return "Not Received";
  else if (status == 1) return "In Transit";
  else if (status == 2) return "Picked Up";
  else if (status == 3) return "At Warehouse";
  else if (status == 4) return "Dispatched";
  else if (status == 5) return "Delivered";
  else return "Unknown";
}

export function getPackageStatusColor(status: number, phase: number) {
  const successClass =
    "bg-green-500 border-2 border-green-600 w-5 h-5 rounded-full";
  const inProgressClass =
    "bg-gray-100 border-2 border-red-600 w-5 h-5 rounded-full";
  const pendingClass =
    "bg-gray-300 border-2 border-gray-400 w-5 h-5 rounded-full";

  switch (phase) {
    // At sender's location
    case 0:
      return status > 0 ? successClass : pendingClass;
    case 1:
      return status >= 3
        ? successClass
        : status > 0
        ? inProgressClass
        : pendingClass;
    case 2:
      return status > 4
        ? successClass
        : status > 3
        ? inProgressClass
        : pendingClass;
    case 3:
      return status >= 5
        ? successClass
        : status > 4
        ? inProgressClass
        : pendingClass;
    default:
      return "";
  }
}
