import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import randomColor from "randomcolor";

// Define a type for the slice state
interface AppState {
  trackingPackages: any[];
  warehouseLocations: any[];
  riderLocations: any[];
  adminRiderLocations: any[];
}

// Define the initial state using that type
const initialState: AppState = {
  trackingPackages: [],
  warehouseLocations: [],
  riderLocations: [],
  adminRiderLocations: [],
};

export const appSlicer = createSlice({
  name: "app",
  initialState,
  reducers: {
    // Packages related reducers
    addPackage(state, action: PayloadAction<any>) {
      const exists = state.trackingPackages.find(
        (p) => p.package_code === action.payload.package_code
      );
      if (exists) return;
      state.trackingPackages.push({
        ...action.payload,
        fillColor: randomColor({ luminosity: "dark", hue: "random" }),
      });

      // No need to set warehouse location if status is not "At warehouse"
      if (action.payload.status !== 3) return;

      // set warehouse location with fillColor
      if (!action.payload.warehouse_id) return;
      const warehouseLocation = state.warehouseLocations.find(
        (l) => l.id === action.payload.warehouse_id
      );
      if (!warehouseLocation) {
        state.warehouseLocations.push({
          ...action.payload.warehouse,
          fillColor: randomColor({ luminosity: "dark", hue: "random" }),
        });
      }
    },
    removePackage(state, action: PayloadAction<any>) {
      const index = state.trackingPackages.findIndex(
        (p) => p.package_code === action.payload.package_code
      );
      if (index !== -1) state.trackingPackages.splice(index, 1);

      // remove rider location if rider doesn't have any other package that is being tracked
      // TODO
    },
    addRiderLocation(state, action: PayloadAction<any>) {
      const exists = state.riderLocations.find(
        (p) => p.staff_id === action.payload.staff_id
      );

      if (exists) {
        const existsIndex = state.riderLocations.findIndex(
          (p) => p.staff_id === action.payload.staff_id
        );
        state.riderLocations[existsIndex] = {
          ...exists,
          ...action.payload,
        };
      } else {
        state.riderLocations.push({
          ...action.payload,
          fillColor: randomColor({ luminosity: "dark", hue: "random" }),
        });
      }
    },
    setAdminRiders(state, action: PayloadAction<any>) {
      // udpate if exists, push if doesn't
      const exists = state.adminRiderLocations.find(
        (p) => p.id === action.payload.id
      );
      if (exists) {
        const existsIndex = state.adminRiderLocations.findIndex(
          (p) => p.id === action.payload.id
        );
        state.adminRiderLocations[existsIndex] = {
          ...exists,
          ...action.payload,
        };
      } else {
        state.adminRiderLocations.push({
          ...action.payload,
          fillColor: randomColor({ luminosity: "dark", hue: "random" }),
        });
      }
    },
  },
});

export const { addPackage, removePackage, addRiderLocation, setAdminRiders } =
  appSlicer.actions;

export default appSlicer.reducer;
