export default function Phone() {
  return (
    <div>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18.0919 22.4372C17.1388 22.4372 15.7999 22.0924 13.795 20.9723C11.357 19.6051 9.47128 18.3429 7.04647 15.9245C4.70858 13.588 3.57089 12.0754 1.9786 9.17789C0.179775 5.90641 0.486416 4.19156 0.829189 3.45865C1.23739 2.58268 1.83993 2.05875 2.61874 1.53873C3.0611 1.24891 3.52922 1.00046 4.01718 0.796544C4.06601 0.775548 4.11142 0.755528 4.15194 0.737462C4.39364 0.628575 4.75985 0.464025 5.22372 0.639806C5.53329 0.756017 5.80966 0.99381 6.24228 1.42106C7.12948 2.29606 8.34189 4.24479 8.78915 5.20182C9.08944 5.84684 9.28818 6.27262 9.28866 6.75016C9.28866 7.30924 9.00741 7.74039 8.66611 8.20572C8.60214 8.29313 8.53866 8.37662 8.47714 8.45768C8.10556 8.94596 8.02401 9.08707 8.07773 9.33902C8.18661 9.84537 8.99862 11.3527 10.3331 12.6842C11.6676 14.0158 13.1314 14.7765 13.6397 14.8849C13.9024 14.9411 14.0465 14.8561 14.5504 14.4713C14.6226 14.4162 14.6969 14.359 14.7745 14.3019C15.295 13.9147 15.7061 13.6408 16.252 13.6408H16.255C16.7301 13.6408 17.1368 13.8468 17.8106 14.1867C18.6895 14.63 20.6969 15.8268 21.5772 16.715C22.0055 17.1466 22.2442 17.422 22.3609 17.7311C22.5367 18.1964 22.3712 18.5612 22.2633 18.8053C22.2452 18.8459 22.2252 18.8903 22.2042 18.9396C21.9987 19.4267 21.7487 19.8938 21.4576 20.3351C20.9386 21.1115 20.4127 21.7126 19.5348 22.1213C19.0839 22.3345 18.5906 22.4425 18.0919 22.4372Z" />
      </svg>
    </div>
  );
}
