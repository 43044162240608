import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

import io from "socket.io-client";
import Swal from "sweetalert2";

import Navigation from "../components/Navigation";
import Map from "../components/Map";

import { setAdminRiders } from "../functions/app";

import { RootState } from "../store";
import { Down, Up } from "../icons";

import { MARKER_PATH, SOCKET_URL } from "../utils/config";

export default function Riders() {
  let [searchParams] = useSearchParams();
  const { adminRiderLocations } = useSelector((store: RootState) => store.app);

  const dispatch = useDispatch();

  // Local state
  const [activeRider, setActiveRider] = useState("");

  // Checking if user is admin
  const isAdmin = searchParams.get("admin") === "admin";

  const changeActiveRider = (id: string) => {
    setActiveRider(id === activeRider ? "" : id);
  };

  useEffect(() => {
    const socket = io(SOCKET_URL);

    socket.on("connect", () => {
      if (isAdmin) socket.emit("APP_ADMIN_TRACK_RIDER");
    });

    socket.on("APP_ADMIN_LOCATION_UPDATE", (data: any) => {
      dispatch(setAdminRiders(data));
    });

    socket.on("ERROR", (data: any) => {
      Swal.fire({
        text: data.message,
        icon: "error",
      });
    });

    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-wrap min-h-screen overflow-y-hidden">
      <div className="w-full md:w-1/3 bg-gray-200 h-screen overflow-y-scroll">
        <div className="h-full">
          <Navigation>
            <div className="my-10">
              {adminRiderLocations.length < 1 ? (
                <div className="flex w-full h-48 bg-gray-50 rounded-sm shadow-md justify-center items-center">
                  <span className="text-gray-400 text-xl">
                    No Rider is currently active!
                  </span>
                </div>
              ) : (
                <div>
                  {adminRiderLocations.map((item, index) => (
                    <div
                      key={index}
                      className="w-full border-t border-x border-cyan-800 "
                    >
                      <div
                        className="bg-[#1E9BEE] px-4 py-3 h-14 flex justify-between cursor-pointer pr-12 relative"
                        onClick={() => changeActiveRider(item.id)}
                      >
                        <div className="font-semibold flex">
                          <p className="my-auto">Rider - {item.id}</p>
                          <svg
                            className="w-8 h-8 my-auto ml-3"
                            fill={item.fillColor}
                            stroke="black"
                            strokeWidth={0.5}
                          >
                            <path d={MARKER_PATH} />
                          </svg>
                        </div>
                        <div className="absolute h-full right-3 top-4 flex">
                          {activeRider === item.id && (
                            <Up width={20} height={20} />
                          )}
                          {activeRider !== item.id && (
                            <Down width={20} height={20} />
                          )}
                        </div>
                      </div>
                      {activeRider === item.id && (
                        <div
                          className="p-5 bg-[#FAFAFA] text-gray-800"
                          key={index}
                        >
                          <div className="py-5 text-sm">
                            <p>
                              <span>Name:</span>
                              <span className="font-semibold ml-3">
                                {item.staff.name ?? "-"}
                              </span>
                            </p>
                            <p>
                              <span>Email:</span>
                              <span className="font-semibold ml-3">
                                {item.staff.email ?? "-"}
                              </span>
                            </p>
                            <p>
                              <span>Phone:</span>
                              <span className="font-semibold ml-3">
                                {item.staff.phone ?? "-"}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Navigation>
        </div>
      </div>
      <div className="w-full md:w-2/3 relative">
        <div className="block md:fixed top-0 h-screen w-full">
          <Map activeRider={activeRider} isAdmin={isAdmin} />
        </div>
      </div>
    </div>
  );
}
