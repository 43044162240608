export default function Contact() {
  return (
    <svg
      width="25"
      height="23"
      viewBox="0 0 25 23"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path d="M14.062 11.5C16.6396 11.5 18.917 9.07275 19.1401 6.08887C19.251 4.58984 18.7808 3.19189 17.8159 2.15332C16.8613 1.12744 15.5268 0.5625 14.062 0.5625C12.5854 0.5625 11.25 1.12402 10.3022 2.14355C9.34374 3.17432 8.87646 4.5752 8.98388 6.08789C9.20312 9.07227 11.48 11.5 14.062 11.5Z" />
      <path d="M24.1885 20.4727C23.7764 18.1865 22.4898 16.2661 20.4683 14.9185C18.6729 13.7217 16.398 13.0625 14.0625 13.0625C11.7271 13.0625 9.45218 13.7217 7.65676 14.918C5.63528 16.2656 4.34866 18.186 3.93655 20.4722C3.84231 20.9961 3.97024 21.5142 4.28762 21.8936C4.43158 22.0664 4.61233 22.205 4.81668 22.2991C5.02103 22.3932 5.24382 22.4405 5.46878 22.4375H22.6563C22.8814 22.4407 23.1043 22.3936 23.3088 22.2995C23.5134 22.2055 23.6943 22.067 23.8384 21.894C24.1548 21.5146 24.2827 20.9966 24.1885 20.4727Z" />
      <path d="M5.07812 13.0625V11.1094H7.03125C7.23845 11.1094 7.43716 11.0271 7.58368 10.8806C7.73019 10.734 7.8125 10.5353 7.8125 10.3281C7.8125 10.1209 7.73019 9.92221 7.58368 9.7757C7.43716 9.62919 7.23845 9.54688 7.03125 9.54688H5.07812V7.59375C5.07812 7.38655 4.99581 7.18784 4.8493 7.04132C4.70279 6.89481 4.50408 6.8125 4.29688 6.8125C4.08967 6.8125 3.89096 6.89481 3.74445 7.04132C3.59794 7.18784 3.51562 7.38655 3.51562 7.59375V9.54688H1.5625C1.3553 9.54688 1.15659 9.62919 1.01007 9.7757C0.86356 9.92221 0.78125 10.1209 0.78125 10.3281C0.78125 10.5353 0.86356 10.734 1.01007 10.8806C1.15659 11.0271 1.3553 11.1094 1.5625 11.1094H3.51562V13.0625C3.51562 13.2697 3.59794 13.4684 3.74445 13.6149C3.89096 13.7614 4.08967 13.8438 4.29688 13.8438C4.50408 13.8438 4.70279 13.7614 4.8493 13.6149C4.99581 13.4684 5.07812 13.2697 5.07812 13.0625Z" />
    </svg>
  );
}
