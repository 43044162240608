export default function Search() {
  return (
    <div>
      <svg
        width="29"
        height="29"
        viewBox="0 0 29 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28.7188 26.2578L20.2367 17.7744C21.6036 15.896 22.339 13.6321 22.3367 11.309C22.3367 5.22842 17.3896 0.28125 11.309 0.28125C5.22842 0.28125 0.28125 5.22842 0.28125 11.309C0.28125 17.3896 5.22842 22.3367 11.309 22.3367C13.6321 22.339 15.896 21.6036 17.7744 20.2367L26.2578 28.7188L28.7188 26.2578ZM11.309 18.8538C9.81657 18.8539 8.35763 18.4115 7.11667 17.5824C5.87571 16.7534 4.90848 15.5749 4.33729 14.1962C3.7661 12.8174 3.61662 11.3002 3.90774 9.83641C4.19887 8.37266 4.91752 7.02812 5.97282 5.97282C7.02812 4.91752 8.37266 4.19887 9.83641 3.90774C11.3002 3.61662 12.8174 3.7661 14.1962 4.33729C15.5749 4.90848 16.7534 5.87571 17.5824 7.11667C18.4115 8.35763 18.8539 9.81657 18.8538 11.309C18.8515 13.3093 18.0558 15.227 16.6414 16.6414C15.227 18.0558 13.3093 18.8515 11.309 18.8538Z"
          fill="#535067"
        />
      </svg>
    </div>
  );
}
