import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface FetchState {
  invalidPackages: string[];
  fetchCount: number;
  shownError: boolean;
}

// Define the initial state using that type
const initialState: FetchState = {
  invalidPackages: [],
  fetchCount: 0,
  shownError: false,
};

export const fetchSlicer = createSlice({
  name: "app",
  initialState,
  reducers: {
    addInvalidPackage(state, action: PayloadAction<string>) {
      const exists = state.invalidPackages.find((p) => p === action.payload);
      if (!exists) state.invalidPackages.push(action.payload);
    },
    incrementFetchCount(state) {
      state.fetchCount++;
    },
    setShownError(state) {
      state.shownError = true;
    },
  },
});

export const { addInvalidPackage, incrementFetchCount, setShownError } =
  fetchSlicer.actions;

export default fetchSlicer.reducer;
