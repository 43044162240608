export const SOCKET_URL = "https://api.tracking.ikimaexpress.com/";
export const G_MAP_KEY = "AIzaSyDZgbj3X6Tik0bP4aQBgm5Fa29Hvd68GJY";

export const MARKER_PATH =
  "M29.92 16.61l-3-7A1 1 0 0 0 26 9h-3V7a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v17a1 1 0 0 0 1 1h2.14a4 4 0 0 0 7.72 0h6.28a4 4 0 0 0 7.72 0H29a1 1 0 0 0 1-1v-7a1 1 0 0 0-.08-.39zM23 11h2.34l2.14 5H23zM9 26a2 2 0 1 1 2-2a2 2 0 0 1-2 2zm10.14-3h-6.28a4 4 0 0 0-7.72 0H4V8h17v12.56A4 4 0 0 0 19.14 23zM23 26a2 2 0 1 1 2-2a2 2 0 0 1-2 2zm5-3h-1.14A4 4 0 0 0 23 20v-2h5z";

export const WAREHOUSE_MARKER_PATH =
  "M 6 22 H 0 V 16 h 6 v 6 z m 8 -6 H 8 v 6 h 6 V 16 z m 8 0 H 16 v 6 h 6 V 16 z M 10 8 H 4 v 6 h 6 V 8 z m 8 0 H 12 v 6 h 6 V 8 z m 2.88 -1.52 a 1 1 90 0 0 -0.38 -1.36 L 11.5 0.12 a 1 1 90 0 0 -0.98 0 l -9 5 a 1 1 90 0 0 0.98 1.74 L 11 2.14 l 8.52 4.74 a 1 1 90 0 0 1.358 -0.396 l 0.002 -0.004 z  ";

export const HOUSE_PATH =
  "M 30.6 13.6 C 30.6 14.45 29.75 15.3 28.9 15.3 H 27.2 L 27.2 23.8 C 27.2 25.5 25.5 27.2 23.8 27.2 H 6.8 C 5.1 27.2 3.4 25.5 3.4 23.8 V 15.3 H 1.7 C 0.85 15.3 0 14.45 0 13.6 C 0 12.75 0 12.75 0.85 11.9 L 14.45 0.85 C 14.45 0 15.3 0 15.3 0 S 16.15 0 16.15 0 L 22.1 5.1 V 3.4 C 22.1 2.55 22.95 1.7 23.8 1.7 H 25.5 C 26.35 1.7 27.2 2.55 27.2 3.4 V 10.2 L 29.75 11.9 C 30.6 12.75 30.6 12.75 30.6 13.6 Z M 13.6 10.2 C 12.75 10.2 11.9 11.05 11.9 11.9 V 16.15 C 11.9 16.15 12.75 17 13.6 17 H 17.85 C 17.85 17 18.7 16.15 18.7 16.15 V 11.9 C 18.7 11.05 17.85 10.2 17.85 10.2 H 13.6 Z";
