import { SVGProps } from "react";

export default function Up(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
