import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

import { useSelector } from "react-redux";
import React from "react";

import { RootState } from "../store";
import {
  G_MAP_KEY,
  HOUSE_PATH,
  MARKER_PATH,
  WAREHOUSE_MARKER_PATH,
} from "../utils/config";

const DEFAULT_CENTER = {
  lat: 27.7036094,
  lng: 85.3272909,
};

interface MapProps {
  // For package based tracking i.e. for regular user
  activePackage?: string;
  // For rider based tracking i.e. for admin tracking all riders
  activeRider?: string;
  // Simple boolean to understand if all riders location should be shown
  isAdmin?: boolean;
}
function Map(props: MapProps) {
  const {
    trackingPackages,
    riderLocations,
    warehouseLocations,
    adminRiderLocations,
  } = useSelector((store: RootState) => store.app);

  const [center, setCenter] = React.useState(DEFAULT_CENTER);

  // Handling center of map for rider based tracking
  React.useEffect(() => {
    if (props.activeRider) {
      const activeRider = adminRiderLocations.find(
        (rider) => rider.id === props.activeRider
      );
      if (!activeRider) return;

      setCenter({
        lat: +activeRider.latitude,
        lng: +activeRider.longitude,
      });
    }
  }, [props.activeRider, adminRiderLocations]);

  // Handling center of map for package based tracking
  React.useEffect(() => {
    // If there is no active package, no need to update center
    if (!props.activePackage) return;

    const activePackage = trackingPackages.find(
      (packageItem) => packageItem.package_code === props.activePackage
    );
    // If no package, no need to update center
    if (!activePackage) return;

    // If package is in warehouse, set center to warehouse location
    if (activePackage.status === 3) {
      const pkgWh = activePackage.warehouse;

      if (pkgWh.latitude && pkgWh.longitude) {
        setCenter({
          lat: +pkgWh.latitude,
          lng: +pkgWh.longitude,
        });
      }
    }

    if (activePackage.status === 4) {
      // If no staff, no need to udpate center
      if (!activePackage.staff_id) return;
      // Getting staff's details in live location is available
      const pkgRider = riderLocations.find(
        (rider) => +rider.staff_id === +activePackage.staff_id
      );
      // If no rider, no need to update center
      if (!pkgRider) return;

      // If rider's location is available, set center to rider's location
      setCenter({
        lat: +pkgRider.latitude,
        lng: +pkgRider.longitude,
      });
    }

    // If package isn't picked up, set center to sender's location
    if (activePackage.status === 0) {
      setCenter({
        lat: +activePackage.sender_lat,
        lng: +activePackage.sender_lon,
      });
    }

    // If package is delivered, set center to reciever's location
    if (activePackage.status === 5) {
      setCenter({
        lat: +activePackage.reciever_lat,
        lng: +activePackage.reciever_lon,
      });
    }
  }, [props.activePackage, riderLocations, trackingPackages]);

  const [locations, setLocations] = React.useState<any[]>([]);
  // Handling markers in the map
  React.useEffect(() => {
    // Admin map
    if (props.isAdmin) {
      const adminRiderMarkers = adminRiderLocations.map((ar: any) => ({
        position: {
          lat: +ar.latitude,
          lng: +ar.longitude,
        },
        fillColor: ar.fillColor,
        type: "vehicle",
      }));

      setLocations(adminRiderMarkers);
      // General user map
    } else {
      const vehicleMarkers = riderLocations.map((rl: any) => ({
        position: {
          lat: +rl.latitude,
          lng: +rl.longitude,
        },
        fillColor: rl.fillColor,
        type: "vehicle",
      }));

      const warehouseMarkers = warehouseLocations.map((wh: any) => ({
        position: {
          lat: +wh.latitude,
          lng: +wh.longitude,
        },
        fillColor: wh.fillColor,
        type: "warehouse",
      }));

      const senderMarkers = trackingPackages
        .filter((tp: any) => tp.status === 0)
        .map((pkg) => ({
          position: {
            lat: +pkg.sender_lat,
            lng: +pkg.sender_lon,
          },
          fillColor: pkg.fillColor,
          type: "house",
        }));

      const recieverMarkers = trackingPackages
        .filter((pkg: any) => pkg.status === 5)
        .map((pkg) => ({
          position: {
            lat: +pkg.reciever_lat,
            lng: +pkg.reciever_lon,
          },
          fillColor: pkg.fillColor,
          type: "house",
        }));

      setLocations([
        ...vehicleMarkers,
        ...warehouseMarkers,
        ...recieverMarkers,
        ...senderMarkers,
      ]);
    }
  }, [
    props.isAdmin,
    riderLocations,
    warehouseLocations,
    adminRiderLocations,
    trackingPackages,
  ]);

  return (
    <LoadScript googleMapsApiKey={G_MAP_KEY}>
      <GoogleMap
        mapContainerClassName="w-full md:w-2/3 h-screen"
        center={center}
        zoom={15}
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          styles: [
            {
              featureType: "poi.business",
              stylers: [{ visibility: "off" }],
            },
          ],
        }}
      >
        {locations.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            icon={{
              path:
                marker.type === "vehicle"
                  ? MARKER_PATH
                  : marker.type === "warehouse"
                  ? WAREHOUSE_MARKER_PATH
                  : HOUSE_PATH,
              fillColor: marker.fillColor,
              fillOpacity: 1,
              strokeWeight: 1,
            }}
          />
        ))}
      </GoogleMap>
    </LoadScript>
  );
}

export default Map;
