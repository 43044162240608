import { SVGProps } from "react";

export default function Down(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 24 24" {...props}>
      <path
        d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6l1.41-1.41z"
        fill="currentColor"
      ></path>
    </svg>
  );
}
