import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import io, { Socket } from "socket.io-client";
import Swal from "sweetalert2";

import Navigation from "../components/Navigation";
import Map from "../components/Map";

import { addPackage, addRiderLocation } from "../functions/app";
import {
  addInvalidPackage,
  setShownError,
  incrementFetchCount,
} from "../functions/fetch";

import { RootState } from "../store";
import { Down, Search, Up } from "../icons";

import { getPackageStatusColor, getPackageStatusText } from "../utils/package";
import {
  HOUSE_PATH,
  MARKER_PATH,
  SOCKET_URL,
  WAREHOUSE_MARKER_PATH,
} from "../utils/config";

export default function Home() {
  let [searchParams] = useSearchParams();
  const { invalidPackages, shownError, fetchCount } = useSelector(
    (store: RootState) => store.fetch
  );
  const {
    trackingPackages: pkgs,
    riderLocations,
    warehouseLocations,
  } = useSelector((store: RootState) => store.app);

  const dispatch = useDispatch();

  // Local state
  const [activeSocket, setActiveSocket] = useState<Socket | null>(null);
  const [activePackage, setActivePackage] = useState("");
  const [userInput, setUserInput] = useState("");

  // Getting tracking codes from URL to fetch on startup
  const urlTrackingCodes = searchParams.get("trackingcode");
  const ids = useMemo(() => {
    return urlTrackingCodes?.split(",")?.filter((id) => id !== "") ?? [];
  }, [urlTrackingCodes]);

  // Checking if user is admin
  const isAdmin = searchParams.get("admin") === "admin";

  const changeActivePackage = (code: string) => {
    setActivePackage(code === activePackage ? "" : code);
  };

  useEffect(() => {
    if (shownError) return;

    if (fetchCount >= ids.length) {
      // No need to display error if no invalid packages are found
      if (!invalidPackages.length) {
        dispatch(setShownError());
        return;
      }

      const text =
        invalidPackages.length === 1
          ? `"${invalidPackages[0]}" is not a valid tracking code!`
          : `"${invalidPackages.join('", "')}" are not valid tracking codes!`;

      Swal.fire({
        text,
        icon: "warning",
      });

      dispatch(setShownError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCount]);

  useEffect(() => {
    const socket = io(SOCKET_URL);
    setActiveSocket(socket);

    socket.on("connect", () => {
      for (const id of ids) {
        socket.emit("APP_TRACK_PACKAGE", id, false);
      }
    });

    socket.on("APP_PACKAGE_INVALID", (id: string) => {
      dispatch(addInvalidPackage(id));
      dispatch(incrementFetchCount());
      setUserInput("");
    });

    socket.on("APP_PACKAGE_DATA", (data: any) => {
      dispatch(incrementFetchCount());
      dispatch(addPackage(data));
      setUserInput("");
      setActivePackage(data.package_code);
    });

    socket.on("APP_LOCATION_UPDATE", (data: any) => {
      dispatch(addRiderLocation(data));
    });

    socket.on("ERROR", (data: any) => {
      Swal.fire({
        text: data.message,
        icon: "error",
      });
      setUserInput("");
    });

    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ids]);

  const startTrack = () => {
    // Ignore empty input
    if (!userInput) return;
    // Show warning for invalid codes
    if (!/[a-z0-9]/gi.test(userInput)) {
      setUserInput("");
      Swal.fire({
        text: "Please enter a valid tracking code!",
        icon: "warning",
      });
      return;
    }
    // If it is already being tracked, highlight it
    if (pkgs.map((pkg: any) => pkg.package_code).includes(userInput)) {
      setActivePackage(userInput);
      setUserInput("");
      return;
    }
    // Start tracking
    activeSocket?.emit("APP_TRACK_PACKAGE", userInput);
  };

  const hasVehicleLocation = (pkg: any) => {
    if (pkg.status !== 4) return false;
    const staff_id = pkgs.find(
      (p: any) => p.package_code === pkg.package_code
    )?.staff_id;
    if (!staff_id) return false;

    const location = riderLocations.find((l: any) => +l.staff_id === +staff_id);
    return location ? true : false;
  };

  const getFillColor = (pkg: any) => {
    if (pkg.status === 3) {
      const warehouseItem = warehouseLocations.find(
        (wh: any) => +wh.id === +pkg.warehouse_id
      );
      return warehouseItem?.fillColor ?? "RANDOM";
    } else if (pkg.status === 4) {
      const staffItem = riderLocations.find(
        (rl: any) => +rl.staff_id === +pkg.staff_id
      );
      return staffItem?.fillColor ?? "RANDOM";
    } else if ([0, 5].includes(pkg.status)) {
      return pkg.fillColor;
    }
  };

  return (
    <div className="flex flex-wrap min-h-screen overflow-y-hidden">
      <div className="w-full md:w-1/3 bg-gray-200 h-screen overflow-y-scroll overflow-x-hidden">
        <div className="h-full">
          <Navigation>
            <div>
              <form
                onSubmit={(e: any) => {
                  e.preventDefault();
                  startTrack();
                }}
              >
                <div className="relative">
                  <div className="absolute flex h-full items-center ml-5">
                    <Search />
                  </div>
                  <input
                    value={userInput}
                    name="lms-tracking-code"
                    id="lms-tracking-code"
                    placeholder="Enter Tracking Number"
                    className="w-full py-4 px-5 pl-[4.5rem] focus:outline-none text-gray-800 rounded-sm border border-gray-300"
                    onChange={(e) => setUserInput(e.target.value)}
                  />
                </div>
              </form>

              <div className="my-10">
                {pkgs.map((item, index) => (
                  <div
                    key={index}
                    className="w-full border-t border-x border-cyan-800"
                  >
                    <div
                      className="bg-[#1E9BEE] px-4 py-3 h-14 flex justify-between cursor-pointer pr-12 relative"
                      onClick={() => changeActivePackage(item.package_code)}
                    >
                      <div className="font-semibold flex">
                        <p className="my-auto">{item.package_code}</p>
                        {item.status === 3 && item.warehouse_id && (
                          <div className="w-6 h-6">
                            <svg
                              className="my-auto ml-3"
                              fill={getFillColor(item)}
                              stroke="black"
                              strokeWidth={0.5}
                            >
                              <path d={WAREHOUSE_MARKER_PATH} />
                            </svg>
                          </div>
                        )}
                        {hasVehicleLocation(item) && (
                          <div className="w-6 h-6">
                            <svg
                              className="my-auto ml-3"
                              fill={getFillColor(item)}
                              stroke="black"
                              strokeWidth={0.5}
                            >
                              <path d={MARKER_PATH} />
                            </svg>
                          </div>
                        )}
                        {[0, 5].includes(item.status) && (
                          <div className="w-6 h-6">
                            <svg
                              className="my-auto ml-3"
                              fill={getFillColor(item)}
                              stroke="black"
                              strokeWidth={0.5}
                            >
                              <path d={HOUSE_PATH} />
                            </svg>
                          </div>
                        )}
                      </div>
                      <p className="text-sm text-yellow-300 my-auto">
                        {getPackageStatusText(item.status)}
                      </p>
                      <div className="absolute h-full right-3 top-4 flex">
                        {activePackage === item.package_code && (
                          <Up width={20} height={20} />
                        )}
                        {activePackage !== item.package_code && (
                          <Down width={20} height={20} />
                        )}
                      </div>
                    </div>
                    {activePackage === item.package_code && (
                      <div
                        className={`p-5 bg-[#FAFAFA] text-gray-800 ${
                          index === pkgs.length - 1 &&
                          "border-b border-cyan-800"
                        }`}
                        key={index}
                      >
                        <p>
                          <span>Tracking Code: </span>
                          <span className="font-semibold">
                            {item.package_code}
                          </span>
                        </p>
                        <p className="text-sm mt-1">
                          <span>Delivery Status: </span>
                          <span className="text-red-600">
                            {getPackageStatusText(item.status)}
                          </span>
                        </p>
                        <div className="my-5 py-5 border-l-2 border-gray-400 ml-4">
                          <PackageLocation item={item} phase={0} />
                          <PackageLocation item={item} phase={1} />
                          <PackageLocation item={item} phase={2} />
                          <PackageLocation item={item} phase={3} />
                        </div>
                        {isAdmin && item.status >= 4 && (
                          <div className="mt-5 py-5 border-t border-gray-400 text-sm">
                            <p>Staff Details:</p>
                            <div className="mt-5">
                              <p>
                                <span>Name:</span>
                                <span className="font-semibold ml-3">
                                  {item.staff.name ?? "-"}
                                </span>
                              </p>
                              <p>
                                <span>Email:</span>
                                <span className="font-semibold ml-3">
                                  {item.staff.email ?? "-"}
                                </span>
                              </p>
                              <p>
                                <span>Phone:</span>
                                <span className="font-semibold ml-3">
                                  {item.staff.phone ?? "-"}
                                </span>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {pkgs.length < 1 && (
                <div className="w-full h-48 flex justify-center items-center bg-gray-50 rounded-sm shadow-md px-10 text-center">
                  <span className="text-gray-400 text-xl select-none">
                    Enter a tracking code to start tracking your package!
                  </span>
                </div>
              )}
            </div>
          </Navigation>
        </div>
      </div>
      <div className="w-full md:w-2/3 relative">
        <div className="block md:fixed top-0 h-screen w-full">
          <Map activePackage={activePackage} isAdmin={false} />
        </div>
      </div>
    </div>
  );
}

interface IPackageLocationProps {
  phase: number;
  item: any;
}
function PackageLocation({ item, phase }: IPackageLocationProps) {
  const statusClass = getPackageStatusColor(item.status, phase);

  return (
    <div
      className={`flex -ml-3 text-sm text-black ${phase === 0 ? "" : "mt-3"}`}
    >
      <div className={statusClass} />
      <div className="ml-3">
        {phase === 0 && <span>{item.sender_address ?? "sender"}</span>}
        {phase === 1 && <span>Received at warehouse</span>}
        {phase === 2 && <span>On the way</span>}
        {phase === 3 && <span>{item.reciever_address ?? "receiver"}</span>}
      </div>
    </div>
  );
}
